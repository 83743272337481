<template>
  <div style="min-height: 750px;">
    <b-row>
      <b-col sm="12" lg="2"></b-col>
      <b-col sm="12" lg="8"></b-col>
      <b-col sm="12" lg="2"></b-col>
    </b-row>
    <b-row style="margin-bottom: 20px; border-bottom: solid 1px whitesmoke;">
      <b-col sm="12" md="2" lg="2"> </b-col>
      <b-col sm="12" md="4" lg="4">
        <i class="fa fa-desktop"></i>
        {{ StoreLangTranslation.data['shop_bundle_product_record'][StoreLang] }}
      </b-col>
      <b-col sm="12" md="4" lg="4" :style="StoreDevice.isMobile ? '' : 'text-align: right;'"></b-col>
      <b-col sm="12" md="2" lg="2"></b-col>
    </b-row>
    <b-row>
      <b-col sm="12" lg="2"></b-col>
      <b-col sm="12" lg="8">
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            <strong>{{ d_userShopBundleProductWdm['0']['parameters']['0']['name']['translation'][StoreLang] }}</strong>
          </b-col>
          <b-col sm="12" lg="8">
            <select class="form-control" v-model="d_userShopBundleProductData.status">
              <option v-for="(op, op_index) in d_userShopBundleProductWdm['0']['parameters']['0'].options" :value="op.value">
                {{ op.translation[StoreLang] }}
              </option>
            </select>
          </b-col>
        </b-row>
        <hr>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            <strong>{{ d_userShopBundleProductWdm['0']['parameters']['1']['name']['translation'][StoreLang] }}</strong>
          </b-col>
          <b-col sm="12" lg="8">
          </b-col>
        </b-row>
        <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
          <b-col sm="12" lg="1">
            {{ lang.label }}
          </b-col>
          <b-col sm="12" lg="11">
            <b-form-textarea rows="5" class="form-control" v-model="d_userShopBundleProductData.pro_name[lang.label]"></b-form-textarea>
          </b-col>
        </b-row>
        <hr>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            <strong>{{ d_userShopBundleProductWdm['0']['parameters']['36']['name']['translation'][StoreLang] }}</strong>
          </b-col>
          <b-col sm="12" lg="8">
            <select class="form-control" v-model="d_userShopBundleProductData.product_type">
              <option v-for="(op, op_index) in d_userShopBundleProductWdm['0']['parameters']['36'].options" :value="op.value">
                {{ op.translation[StoreLang] }}
              </option>
            </select>
          </b-col>
        </b-row>
        <hr>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            <strong>{{ d_userShopBundleProductWdm['0']['parameters']['37']['name']['translation'][StoreLang] }}</strong>
          </b-col>
          <b-col sm="12" lg="8">
          </b-col>
        </b-row>
        <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
          <b-col sm="12" lg="1">
            {{ lang.label }}
          </b-col>
          <b-col sm="12" lg="11">
            <input type="text" class="form-control" v-model="d_userShopBundleProductData.wanalyzer_project_id[lang.label]">
          </b-col>
        </b-row>
        <hr>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            <strong>{{ d_userShopBundleProductWdm['0']['parameters']['38']['name']['translation'][StoreLang] }}</strong>
          </b-col>
          <b-col sm="12" lg="8">
          </b-col>
        </b-row>
        <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
          <b-col sm="12" lg="1">
            {{ lang.label }}
          </b-col>
          <b-col sm="12" lg="11">
            <input type="text" class="form-control" v-model="d_userShopBundleProductData.wanalyzer_stats_id[lang.label]">
          </b-col>
        </b-row>
        <hr>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            <strong>{{ d_userShopBundleProductWdm['0']['parameters']['39']['name']['translation'][StoreLang] }}</strong>
          </b-col>
          <b-col sm="12" lg="8">
          </b-col>
        </b-row>
        <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
          <b-col sm="12" lg="1">
            {{ lang.label }}
          </b-col>
          <b-col sm="12" lg="11">
            <input type="text" class="form-control" v-model="d_userShopBundleProductData.wanalyzer_machinelearning_id[lang.label]">
          </b-col>
        </b-row>
        <hr>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            <strong>{{ d_userShopBundleProductWdm['0']['parameters']['3']['name']['translation'][StoreLang] }}</strong>
          </b-col>
          <b-col sm="12" lg="8">
            <select class="form-control" v-model="d_userShopBundleProductData.can_assign_auth">
              <option v-for="(op, op_index) in d_userShopBundleProductWdm['0']['parameters']['3'].options" :value="op.value">
                {{ op.translation[StoreLang] }}
              </option>
            </select>
          </b-col>
        </b-row>
        <hr>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            <strong>{{ d_userShopBundleProductWdm['0']['parameters']['4']['name']['translation'][StoreLang] }}</strong>
          </b-col>
          <b-col sm="12" lg="8">
          </b-col>
        </b-row>
        <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
          <b-col sm="12" lg="1">
            {{ lang.label }}
          </b-col>
          <b-col sm="12" lg="11">
            <input type="text" class="form-control" v-model="d_userShopBundleProductData.pro_auth_id[lang.label]">
          </b-col>
        </b-row>
        <hr>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            <strong>{{ d_userShopBundleProductWdm['0']['parameters']['5']['name']['translation'][StoreLang] }}</strong>
          </b-col>
          <b-col sm="12" lg="8">
          </b-col>
        </b-row>
        <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
          <b-col sm="12" lg="1">
            {{ lang.label }}
          </b-col>
          <b-col sm="12" lg="11">
            <b-form-textarea rows="5" class="form-control" v-model="d_userShopBundleProductData.pro_info[lang.label]"></b-form-textarea>
          </b-col>
        </b-row>
        <hr>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            <strong>{{ d_userShopBundleProductWdm['0']['parameters']['28']['name']['translation'][StoreLang] }}</strong>
          </b-col>
          <b-col sm="12" lg="8">
          </b-col>
        </b-row>
        <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
          <b-col sm="12" lg="1">
            {{ lang.label }}
          </b-col>
          <b-col sm="12" lg="11">
            <input type="text" class="form-control" v-model="d_userShopBundleProductData.tax_rate_percent[lang.label]">
          </b-col>
        </b-row>
        <hr>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            <strong>{{ d_userShopBundleProductWdm['0']['parameters']['6']['name']['translation'][StoreLang] }}</strong>
          </b-col>
          <b-col sm="12" lg="8">
          </b-col>
        </b-row>
        <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
          <b-col sm="12" lg="1">
            {{ lang.label }}
          </b-col>
          <b-col sm="12" lg="11">
            <input type="date" class="form-control" v-model="d_userShopBundleProductData.pro_start_date[lang.label]">
          </b-col>
        </b-row>
        <hr>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            <strong>{{ d_userShopBundleProductWdm['0']['parameters']['7']['name']['translation'][StoreLang] }}</strong>
          </b-col>
          <b-col sm="12" lg="8">
          </b-col>
        </b-row>
        <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
          <b-col sm="12" lg="1">
            {{ lang.label }}
          </b-col>
          <b-col sm="12" lg="11">
            <input type="date" class="form-control" v-model="d_userShopBundleProductData.pro_end_date[lang.label]">
          </b-col>
        </b-row>
        <hr>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            <strong>{{ d_userShopBundleProductWdm['0']['parameters']['8']['name']['translation'][StoreLang] }}</strong>
          </b-col>
          <b-col sm="12" lg="8">
          </b-col>
        </b-row>
        <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
          <b-col sm="12" lg="1">
            {{ lang.label }}
          </b-col>
          <b-col sm="12" lg="11">
            <input type="text" class="form-control" v-model="d_userShopBundleProductData.pro_group_name[lang.label]">
          </b-col>
        </b-row>
        <hr>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            <strong>{{ d_userShopBundleProductWdm['0']['parameters']['9']['name']['translation'][StoreLang] }}</strong>
          </b-col>
          <b-col sm="12" lg="8">
          </b-col>
        </b-row>
        <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
          <b-col sm="12" lg="1">
            {{ lang.label }}
          </b-col>
          <b-col sm="12" lg="11">
            <input type="text" class="form-control" v-model="d_userShopBundleProductData.pro_usage_count[lang.label]">
          </b-col>
        </b-row>
        <hr>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            <strong>{{ d_userShopBundleProductWdm['0']['parameters']['10']['name']['translation'][StoreLang] }}</strong>
          </b-col>
          <b-col sm="12" lg="8">
          </b-col>
        </b-row>
        <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
          <b-col sm="12" lg="1">
            {{ lang.label }}
          </b-col>
          <b-col sm="12" lg="11">
            <input type="text" class="form-control" v-model="d_userShopBundleProductData.pro_count_suffix[lang.label]">
          </b-col>
        </b-row>
        <hr>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            <strong>{{ d_userShopBundleProductWdm['0']['parameters']['11']['name']['translation'][StoreLang] }}</strong>
          </b-col>
          <b-col sm="12" lg="8">
          </b-col>
        </b-row>
        <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
          <b-col sm="12" lg="1">
            {{ lang.label }}
          </b-col>
          <b-col sm="12" lg="11">
            <input type="text" class="form-control" v-model="d_userShopBundleProductData.pay_delay_max_days[lang.label]">
          </b-col>
        </b-row>
        <hr>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            <strong>{{ d_userShopBundleProductWdm['0']['parameters']['12']['name']['translation'][StoreLang] }}</strong>
          </b-col>
          <b-col sm="12" lg="8">
          </b-col>
        </b-row>
        <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
          <b-col sm="12" lg="1">
            {{ lang.label }}
          </b-col>
          <b-col sm="12" lg="11">
            <input type="text" class="form-control" v-model="d_userShopBundleProductData.organization_id[lang.label]">
          </b-col>
        </b-row>
        <hr>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            <strong>{{ d_userShopBundleProductWdm['0']['parameters']['13']['name']['translation'][StoreLang] }}</strong>
          </b-col>
          <b-col sm="12" lg="8">
          </b-col>
        </b-row>
        <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
          <b-col sm="12" lg="1">
            {{ lang.label }}
          </b-col>
          <b-col sm="12" lg="11">
            <input type="text" class="form-control" v-model="d_userShopBundleProductData.organization_name[lang.label]">
          </b-col>
        </b-row>
        <hr>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            <strong>{{ d_userShopBundleProductWdm['0']['parameters']['14']['name']['translation'][StoreLang] }}</strong>
          </b-col>
          <b-col sm="12" lg="8">
          </b-col>
        </b-row>
        <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
          <b-col sm="12" lg="1">
            {{ lang.label }}
          </b-col>
          <b-col sm="12" lg="11">
            <input type="text" class="form-control" v-model="d_userShopBundleProductData.organization_user_id[lang.label]">
          </b-col>
        </b-row>
        <hr>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            <strong>{{ d_userShopBundleProductWdm['0']['parameters']['15']['name']['translation'][StoreLang] }}</strong>
          </b-col>
          <b-col sm="12" lg="8">
          </b-col>
        </b-row>
        <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
          <b-col sm="12" lg="1">
            {{ lang.label }}
          </b-col>
          <b-col sm="12" lg="11">
            <input type="text" class="form-control" v-model="d_userShopBundleProductData.organization_username[lang.label]">
          </b-col>
        </b-row>
        <hr>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            <strong>{{ d_userShopBundleProductWdm['0']['parameters']['16']['name']['translation'][StoreLang] }}</strong>
          </b-col>
          <b-col sm="12" lg="8">
            <select class="form-control" v-model="d_userShopBundleProductData.preparation_type">
              <option v-for="(op, op_index) in d_userShopBundleProductWdm['0']['parameters']['16'].options" :value="op.value">
                {{ op.translation[StoreLang] }}
              </option>
            </select>
          </b-col>
        </b-row>
        <hr>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            <strong>{{ d_userShopBundleProductWdm['0']['parameters']['17']['name']['translation'][StoreLang] }}</strong>
          </b-col>
          <b-col sm="12" lg="8">
            <select class="form-control" v-model="d_userShopBundleProductData.task_type">
              <option v-for="(op, op_index) in d_userShopBundleProductWdm['0']['parameters']['17'].options" :value="op.value">
                {{ op.translation[StoreLang] }}
              </option>
            </select>
          </b-col>
        </b-row>
        <hr>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            <strong>{{ d_userShopBundleProductWdm['0']['parameters']['18']['name']['translation'][StoreLang] }}</strong>
          </b-col>
          <b-col sm="12" lg="8">
            <select class="form-control" v-model="d_userShopBundleProductData.priority">
              <option v-for="(op, op_index) in d_userShopBundleProductWdm['0']['parameters']['18'].options" :value="op.value">
                {{ op.translation[StoreLang] }}
              </option>
            </select>
          </b-col>
        </b-row>
        <hr>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            <strong>{{ d_userShopBundleProductWdm['0']['parameters']['19']['name']['translation'][StoreLang] }}</strong>
          </b-col>
          <b-col sm="12" lg="8">
            <select class="form-control" v-model="d_userShopBundleProductData.pay_type">
              <option v-for="(op, op_index) in d_userShopBundleProductWdm['0']['parameters']['19'].options" :value="op.value">
                {{ op.translation[StoreLang] }}
              </option>
            </select>
          </b-col>
        </b-row>
        <hr>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            <strong>{{ d_userShopBundleProductWdm['0']['parameters']['20']['name']['translation'][StoreLang] }}</strong>
          </b-col>
          <b-col sm="12" lg="8">
            <select class="form-control" v-model="d_userShopBundleProductData.pay_period_type">
              <option v-for="(op, op_index) in d_userShopBundleProductWdm['0']['parameters']['20'].options" :value="op.value">
                {{ op.translation[StoreLang] }}
              </option>
            </select>
          </b-col>
        </b-row>
        <hr>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            <strong>{{ d_userShopBundleProductWdm['0']['parameters']['21']['name']['translation'][StoreLang] }}</strong>
          </b-col>
          <b-col sm="12" lg="8">
            <select class="form-control" v-model="d_userShopBundleProductData.pay_credit_card">
              <option v-for="(op, op_index) in d_userShopBundleProductWdm['0']['parameters']['21'].options" :value="op.value">
                {{ op.translation[StoreLang] }}
              </option>
            </select>
          </b-col>
        </b-row>
        <hr>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            <strong>{{ d_userShopBundleProductWdm['0']['parameters']['22']['name']['translation'][StoreLang] }}</strong>
          </b-col>
          <b-col sm="12" lg="8">
            <select class="form-control" v-model="d_userShopBundleProductData.pay_transfer_to_account">
              <option v-for="(op, op_index) in d_userShopBundleProductWdm['0']['parameters']['22'].options" :value="op.value">
                {{ op.translation[StoreLang] }}
              </option>
            </select>
          </b-col>
        </b-row>
        <hr>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            <strong>{{ d_userShopBundleProductWdm['0']['parameters']['23']['name']['translation'][StoreLang] }}</strong>
          </b-col>
          <b-col sm="12" lg="8">
            <select class="form-control" v-model="d_userShopBundleProductData.pro_usage_count_type">
              <option v-for="(op, op_index) in d_userShopBundleProductWdm['0']['parameters']['23'].options" :value="op.value">
                {{ op.translation[StoreLang] }}
              </option>
            </select>
          </b-col>
        </b-row>
        <hr>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            <strong>{{ d_userShopBundleProductWdm['0']['parameters']['24']['name']['translation'][StoreLang] }}</strong>
          </b-col>
          <b-col sm="12" lg="8">
            <select class="form-control" v-model="d_userShopBundleProductData.currency_type">
              <option v-for="(op, op_index) in d_userShopBundleProductWdm['0']['parameters']['24'].options" :value="op.value">
                {{ op.translation[StoreLang] }}
              </option>
            </select>
          </b-col>
        </b-row>
        <hr>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="6">
          </b-col>
          <b-col sm="12" lg="6" style="text-align: right;">
            <b-button variant="secondary" @click="f_addNewCurrencyAmountItem()">
              <i class="fa fa-plus-circle"></i> yeni özellik
            </b-button>
          </b-col>
        </b-row>
        <template v-if="d_userShopBundleProductData.currency_amount_list.length > 0">
          <b-row v-for="(currency_amount_list, currency_amount_index) in d_userShopBundleProductData.currency_amount_list" :style="f_calculateCurrencyAmountItemStyle(currency_amount_index)">
            <b-col sm="12" lg="12">
              <b-row style="margin: 3px;">
                <b-col sm="12" lg="10">
                  <strong>{{ currency_amount_index + 1 }} )</strong>
                </b-col>
                <b-col sm="12" lg="2">
                  <b-button variant="danger" @click="f_deleteCurrencyAmountItem(currency_amount_index)">
                    {{ StoreLangTranslation.data['delete'][StoreLang] }}
                  </b-button>
                </b-col>
              </b-row>
              <hr>
              <b-row style="margin: 3px;">
                <b-col sm="12" lg="4">
                  <strong>{{ d_userShopBundleProductWdm['0']['parameters']['25']['parameters']['26']['name']['translation'][StoreLang] }}</strong>
                </b-col>
                <b-col sm="12" lg="8">
                  <select class="form-control" v-model="currency_amount_list.currency_type">
                    <option v-for="(op, op_index) in d_userShopBundleProductWdm['0']['parameters']['25']['parameters']['26'].options" :value="op.value">
                      {{ op.translation[StoreLang] }}
                    </option>
                  </select>
                </b-col>
              </b-row>
              <hr>
              <b-row style="margin: 3px;">
                <b-col sm="12" lg="4">
                  <strong>{{ d_userShopBundleProductWdm['0']['parameters']['25']['parameters']['27']['name']['translation'][StoreLang] }}</strong>
                </b-col>
                <b-col sm="12" lg="8">
                </b-col>
              </b-row>
              <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                <b-col sm="12" lg="1">
                  {{ lang.label }}
                </b-col>
                <b-col sm="12" lg="11">
                  <input type="text" class="form-control" v-model="currency_amount_list.currency_amount[lang.label]">
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </template>
        <hr>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="8">
          </b-col>
          <b-col sm="12" lg="4" style="text-align: right;">
            <b-button variant="success" style="width: 100%;" @click="f_userShopBundleProductRecord()">
              <i class="fa fa-save"></i> {{ StoreLangTranslation.data['save'][StoreLang] }}
            </b-button>
          </b-col>
        </b-row>
      </b-col>
      <b-col sm="12" lg="2"></b-col>
    </b-row>
  </div>
</template>

<script>
import { data as system_menu_list } from '@/options/system_menu_list';
import store from '@/store';
import UserShopService from '@/services/user_shop';
import { data as usr_shp_bund_pro } from '@/options/usr_shp_bund_pro';
import {
  default as PageInfo
} from '@/components/widgets/PageInfo';
import { mapGetters } from 'vuex';
export default {
  name: 'user_shop_bundle_product_record',
  computed: mapGetters({
    StoreDevice: 'StoreDevice',
    StoreLang: 'StoreLang',
    StoreLangList: 'StoreLangList',
    StoreLangTranslation: 'StoreLangTranslation',
    StoreFrontendSettings: 'StoreFrontendSettings',
  }),
  components: {
    PageInfo,
  },
  props: {},
  data () {
    return {
      d_user: '',
      d_frontendClientID: '',
      d_userShopClientID: '',
      d_userShopBundleClientID: '',
      d_userShopBundleProductClientID: '',
      d_frontendList: [],
      d_userShopBundleProductWdm: usr_shp_bund_pro,
      d_userShopBundleProductData: {
        'status': '0',
        'product_type': '0',
        'wanalyzer_project_id': {},
        'wanalyzer_stats_id': {},
        'wanalyzer_machinelearning_id': {},
        'pro_name': {},
        'can_assign_auth': '0',
        'pro_auth_id': {},
        'pro_info': {},
        'tax_rate_percent': {},
        'pro_end_date': {},
        'pro_start_date': {},
        'pro_group_name': {},
        'pro_usage_count': {},
        'pro_count_suffix': {},
        'pay_delay_max_days': {},
        'organization_id': {},
        'organization_name': {},
        'organization_user_id': {},
        'organization_username': {},
        'preparation_type': '1',
        'task_type': '',
        'priority': '',
        'pay_type': '',
        'pay_period_type': '',
        'pay_credit_card': '',
        'pay_transfer_to_account': '',
        'pro_usage_count_type': '',
        'currency_type': '',
        'currency_amount_list': [],
        'show_in_bundle_product_list': '1',
        'expiration_time_type': '2',
        'in_store_visibility': '1',
        'expiration_time_value': {},
        'expiration_time_value_by_text': {},
        'price_includes_taxes': '0',
        'organization_token': {},
      }
    };
  },
  created: function () {
    this.d_user = JSON.parse(localStorage.getItem('user'));
  },
  beforeMount () {},
  mounted: function () {
    if (this.$route.query.user_shop_client_id) {
      this.d_userShopClientID = this.$route.query.user_shop_client_id;
    }
    if (this.$route.query.user_shop_bundle_client_id) {
      this.d_userShopBundleClientID = this.$route.query.user_shop_bundle_client_id;
    }
    if (this.$route.query.user_shop_bundle_product_client_id) {
      this.d_userShopBundleProductClientID = this.$route.query.user_shop_bundle_product_client_id;
    }
    if (this.d_userShopBundleProductClientID) {
      this.f_userShopBundleProductGet();
    }
    if (!this.d_userShopClientID) {
      alert(this.StoreLangTranslation.data['shop_is_not_selected'][this.StoreLang]);
      this.f_goToShopList();
    }
    if (!this.d_userShopBundleClientID) {
      alert(this.StoreLangTranslation.data['bundle_is_not_selected'][this.StoreLang]);
      this.f_goToShopList();
    }
    if (!this.d_userShopBundleProductClientID) {
      this.f_langEligibleParameterProcess();
    }
    // console.log(this.StoreLangList);
    // console.log(this.StoreFrontendSettings);
    // console.log(this.d_userShopBundleProductWdm);
  },
  methods: {
    f_deleteCurrencyAmountItem: function (currency_amount_index) {
      this.d_userShopBundleProductData.currency_amount_list.splice(currency_amount_index, 1);
    },
    f_addNewCurrencyAmountItem: function () {
      let lang_eligible_variables = ['currency_amount'];
      let new_item = { 'currency_type': '', 'currency_amount': {} };
      for (let k in lang_eligible_variables) {
        for (let lang_index in this.StoreLangList.data) {
          new_item[lang_eligible_variables[k]][this.StoreLangList.data[lang_index].label] = '';
        }
      }
      this.d_userShopBundleProductData.currency_amount_list.push(new_item);
    },
    f_calculateCurrencyAmountItemStyle: function (currency_amount_index) {
      let style = 'margin: 3px;';
      if (currency_amount_index % 2 === 0) {
        style += 'background-color: #99e4e8';
      } else {
        style += 'background-color: #99e4e8';
        // style += 'background-color: white;';
      }
      return style;
    },
    f_goToShopList: function () {
      this.$router.push({ path: this.f_getRoutePath('user_shop_list'), query: {} });
    },
    f_langEligibleParameterProcess: function () {
      let lang_eligible_variables = ['pro_name', 'pro_auth_id', 'organization_id', 'organization_name', 'organization_user_id', 'organization_username', 'expiration_time_value', 'expiration_time_value_by_text', 'organization_token', 'wanalyzer_project_id', 'wanalyzer_stats_id', 'wanalyzer_machinelearning_id'];
      for (let k in lang_eligible_variables) {
        for (let lang_index in this.StoreLangList.data) {
          if (!this.d_userShopBundleProductData[lang_eligible_variables[k]][this.StoreLangList.data[lang_index].label]) {
            this.d_userShopBundleProductData[lang_eligible_variables[k]][this.StoreLangList.data[lang_index].label] = '';
          }
        }
      }
    },
    f_getRoutePath: function (target_route_name_en) {
      let route_path = '';
      for (let i in system_menu_list) {
        if (system_menu_list[i].route_name.en == target_route_name_en) {
          route_path = system_menu_list[i].route_path[this.StoreLang];
        }
        if (!route_path) {
          if (system_menu_list[i].list && system_menu_list[i].list.length) {
            for (let k in system_menu_list[i].list) {
              if (system_menu_list[i].list[k].route_name.en == target_route_name_en) {
                route_path = system_menu_list[i].list[k].route_path[this.StoreLang];
                break;
              }
            }
          }
        }
        if (route_path) {
          break;
        }
      }
      return route_path;
    },
    f_userShopBundleProductRecord: function () {
      let query = 'user_client_id=' + this.d_user.username;
      let data = {};
      if (this.d_userShopClientID) {
        query += '&user_shop_client_id=' + this.d_userShopClientID;
      }
      if (this.d_userShopBundleClientID) {
        query += '&user_shop_bundle_client_id=' + this.d_userShopBundleClientID;
      }
      if (this.d_userShopBundleProductClientID) {
        query += '&user_shop_bundle_product_client_id=' + this.d_userShopBundleProductClientID;
      }
      for (let i in this.d_userShopBundleProductData) {
        data[i] = this.d_userShopBundleProductData[i];
      }
      if (this.d_userShopBundleProductClientID) {
        UserShopService.user_shop_bundle_product_edit(query, data)
          .then(resp => {
            if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
              alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
            } else {
              alert(resp.data.status_code, ' ', resp.data.status_message);
            }
          });
      } else {
        UserShopService.user_shop_bundle_product_new(query, data)
          .then(resp => {
            if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
              alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
            } else {
              alert(resp.data.status_code, ' ', resp.data.status_message);
            }
          });
      }
    },
    f_userShopBundleProductGet: function () {
      let query = 'user_shop_bundle_client_id=' + this.d_userShopBundleClientID;
      query += '&data_type=value_mode';
      query += '&user_shop_client_id=' + this.d_userShopClientID;
      query += '&user_shop_bundle_product_client_id=' + this.d_userShopBundleProductClientID;
      query += '&user_client_id=' + this.d_user.username;
      let data = {};
      UserShopService.user_shop_bundle_product_get(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            if (resp.data.data) {
              this.d_userShopBundleProductData = resp.data.data;
              this.f_langEligibleParameterProcess();
            } else {
              alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
            }
          } else {
            alert(resp.data.status_code, ' ', resp.data.status_message);
          }
        });
    },
  }
};

</script>

